.panel{
  height: 80vh;
  width: 95vw;
  border-radius: 10px;
}


.grid-menu{
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin: 0px 10px;
  grid-template-rows: 20vh 80vh;
}


.boxLink {
  border: 1px solid green;
  background: #00800040;
  font-size: 15px;
  border-radius: 10px;
  padding: 15px;
}

.boxOnline {
  border-radius: 5px;
  padding: 2px 30px 2px 30px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  display: flex;
}

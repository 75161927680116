.panel{
    height: 100vh;
    display: flex;
    background-color: white ;
    justify-content: center;
    color: black;
    align-items: center;
}

.logo{
    width: 100%;
    max-width: 250px;
    height: auto;
}

.backgroundImage {
    position: absolute;
    padding: 0px;
    background-image: url('../../assets/background-login.jpg');
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.form{
    padding: 30px;
    border: 1px solid #ababab;
    background-color: white ;
    color:black;
    border-radius: 20px;
}

.form h3{
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
}

.form label{
    color: #fff;
}

.form button{
    color: white;
    font-weight: bold;
}




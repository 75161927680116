.panel{
    height: 80vh;
    width: 95vw;
    border-radius: 10px;
  }

.panelCadastroFuncionarios{
  height: 80vh;
  width: 95vw;
  border-radius: 10px;
  background: white;
}

.grid-menu{
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin: 0px 10px;
  grid-template-rows: 20vh 80vh;
}


.parent {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: px;
  }
  
  .div1 { grid-area: 1 / 1 / 6 / 2; }
  .div2 { grid-area: 1 / 2 / 6 / 3; }
  .div3 { grid-area: 1 / 3 / 6 / 4; }
  .div4 { grid-area: 1 / 4 / 6 / 5; }
  
  
iframe
{
    display: none;
}
.header {
  background-color: #003958 !important;
  opacity: 1 !important;
  color: white;
  text-align: center;
} 
.App {
  text-align: center;
}
div[role="dialog"] {
  overflow: hidden;
}
.header-info {
  background-color: #003958;
  color: white;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  gap: 3;
}

.text-table{
  font-weight: bold;
  color: black;
  font-size: 15px;
  padding: 10px;
}

.MuiDataGrid-columnHeaders .MuiDataGrid-withBorderColor .css-1iyq7zh-MuiDataGrid-columnHeaders {
  max-height: 100px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn-default:hover {
  background-color: none !important;
  color: black !important; 
}


:root{
 
  --toastify-icon-color-info: #003958;
  --toastify-icon-color-success: #003958;
  --toastify-icon-color-warning: #003958;
  --toastify-icon-color-error: red;

  --toastify-color-success: #003958;
  --toastify-color-warning: #003958;

}  
  
html{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

form{
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px 40px;
  background-color: "#582f547d";
  width: 400px;
}

.icon-warning {
  background: #273B4C;
  border-radius: 3px;
  padding: 5px;
  color: white;
  font-size: 30px;
  margin-right: 5px;
  margin-bottom: 5px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.camera-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

#video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Escurece o fundo */
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 300px;
  height: 300px;
  background-color: transparent;
  border: 4px solid white; /* Cor da borda */
  border-radius: 50%; /* Faz o círculo */
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.7); /* Escurece fora do círculo */
}